import React from "react";
import {
  makeStyles,
  Divider,
  Grid,
  Card,
  CardContent,
  Typography,
  CardMedia,
} from "@material-ui/core";

import { Link } from "react-router-dom";
import maincolors from "../Colors/Color.js";
import Rectangle from "../Layout/Rectangle.jsx";
import Brochure from "./brochure_scuolalimes_2025_dig.pdf";

const useStyles = makeStyles((theme) => ({
  block: {
    marginTop:60,
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    "& a": {
      display: "block",
      paddingTop: 16,
      color: maincolors.orange,

      marginBottom: 16,
      [theme.breakpoints.down("md")]: {
        paddingRight: 16,
      },
      [theme.breakpoints.down("sm")]: {
        paddingRight: 16,
      },
    },
  },
  title: {
    paddingBottom: "1rem",
    textTransform: "uppercase",
  },
  media: {
    height: 250,
  },
  root: {
    boxShadow: "none",
  },
  cardContent: {
    padding: "16px 0",
  },
  text: {
    paddingTop: "1rem",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: 16,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 16,
    },
  },
  div: {
    marginBottom: 115,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 56,
    },
  },
  link: {
    display: "block",
  },
}));

const IlClubAlumni = () => {
  const classes = useStyles();
  return (
    <div className="section-wrapper">
      <Rectangle />
      <div className={classes.block}>
        <Typography variant="h1" color="initial">
          IL CLUB ALUMNI
        </Typography>
        <Divider />
        <Grid container>
          <Grid item md={12}>
            <Typography className={classes.text}>
              La Scuola è una comunità in crescita. Non dura un anno soltanto. Al termine del corso, i partecipanti si iscrivono al Club Alumni, per costruire una rete di relazioni. Gli Alumni partecipano a eventi riservati e mantengono i contatti con il mondo di Limes.
              <a href={Brochure} target="blank" alt="brochure_alumni-limes_23">Scarica la brochure</a>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default IlClubAlumni;

import React, { useEffect } from "react";

const TrackingScript = ({ pageName }) => {
  const pmValue = 3239568;

  const trackingType = (() => {
    switch (pageName) {
      case "ScuoladiLimes|Homepage":
        return "Standard (asynchronous)";
      case "ScuoladiLimes|PageForm":
      case "ScuoladiLimes|TYP":
        return "Default (Asynchronous)";
      default:
        return "Default (Asynchronous)";
    }
  })();

  useEffect(() => {
    if (typeof window !== "undefined") {
      window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);

      window._adftrack.push({
        HttpHost: "track.adform.net",
        pm: pmValue,
        divider: encodeURIComponent("|"),
        pagename: encodeURIComponent(pageName),
        trackingType,
      });

      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = "https://s2.adform.net/banners/scripts/st/trackpoint-async.js";

      // Check if the script tag exists before accessing its parentNode
      const firstScript = document.getElementsByTagName("script")[0];
      if (firstScript) {
        firstScript.parentNode.insertBefore(script, firstScript);
      } else {
        // If no script tag exists, append the script to the <head> instead
        const head = document.head || document.getElementsByTagName("head")[0];
        head.appendChild(script);
      }
    }
  }, [pageName]);

  return (
    <React.Fragment>
      <noscript>
        <p style={{ margin: 0, padding: 0, border: 0 }}>
          <img
            src={`https://track.adform.net/Serving/TrackPoint/?pm=${pmValue}&ADFPageName=${encodeURIComponent(pageName)}&ADFdivider=%7C`}
            width="1"
            height="1"
            alt="what is this"
          />
        </p>
      </noscript>
      <div style={{ display: "none" }}>
        Tracking code: {trackingType} "{pageName}"
      </div>
    </React.Fragment>
  );
};

export default TrackingScript;

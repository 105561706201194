import React from "react";
import {
  makeStyles,
  Divider,
  Grid,
  Card,
  CardContent,
  Typography,
  CardMedia,
} from "@material-ui/core";
import logo1 from "../../images/Diplomatici-small.png";
import logo2 from "../../images/confitarma-small.png";
import logo3 from "../../images/Leonardo-small.png";
import logo4 from "../../images/Edison_com_RGB-small.png";
import logo5 from "../../images/telt-logo-CMYK.gif";
import logo6 from "../../images/MBDA-logo.jpg";

import maincolors from "../Colors/Color.js";
import Rectangle from "../Layout/Rectangle.jsx";

const useStyles = makeStyles((theme) => ({
  block: {
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    "& a": {
      display: "block",
      paddingTop: 16,
      color: maincolors.orange,

      marginBottom: 16,
      [theme.breakpoints.down("md")]: {
        paddingRight: 16,
      },
      [theme.breakpoints.down("sm")]: {
        paddingRight: 16,
      },
    },
  },
  title: {
    paddingBottom: "1rem",
    textTransform: "uppercase",
  },
  media: {
    height: 250,
  },
  root: {
    boxShadow: "none",
  },
  cardContent: {
    padding: "16px 0",
  },
  text: {
    paddingTop: "1rem",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: 16,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 16,
    },
  },
  div: {
    marginBottom: 115,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 56,
    },
  },
  link: {
    display: "block",
  },
  container: {
    "& img": {
      maxWidth: "100%",
      width: 225,
      height: "auto",
    },
  },
  containerGrid: {
    justifyContent: "center",
  },

  partnerTitle: {
    textAlign: "center",
  },
  secondRow: {
    "& img": {
      width: 290,
    },
  },
}));

const INostriPartner = () => {
  const classes = useStyles();
  return (
    <div className="section-wrapper">
      <Rectangle />
      <div className={classes.block}>
        <Typography variant="h1" color="initial">
          I NOSTRI PARTNER
        </Typography>
        <Divider />
        <Grid container className={`logos-contanier ${classes.container}`}>
          <Grid item md={12}>
            <Typography className={classes.text}>
              Negli anni Limes ha intessuto una rete di rapporti piuttosto
              strutturati con diverse aziende e istituzioni italiane centrali
              nella proiezione o nella rappresentazione dell'interesse nazionale
              del nostro paese. Questi attori sostengono in vario modo la nostra
              Scuola.
            </Typography>
          </Grid>
          <Grid container>
            <Grid item md={12} sm={12}>
              <div className={classes.mainPartner}>
                <Grid>
                  <Typography
                    className={`${classes.partnerTitle}  ${classes.first} ${classes.text}`}
                  >
                    {" "}
                    Main Partner
                  </Typography>
                </Grid>
                <Grid container className={classes.containerGrid}>
                  <Grid>
                    <img className="logo_desktop" src={logo3} />
                  </Grid>
                  <Grid>
                    <img className="logo_desktop" src={logo4} />
                  </Grid>
                  
                  <Grid>
                    <img className="logo_desktop tunnel-image" src={logo5} />
                  </Grid>

                  <Grid className="MBDA-logo-container">
                    <img className="logo_desktop" src={logo6} />
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item md={12} sm={12} className={classes.partner}>
              <div className={classes.morePartners}>
                <Grid>
                  <Typography
                    className={`${classes.partnerTitle} ${classes.second} ${classes.text}`}
                  >
                    Partner
                  </Typography>
                </Grid>
                <Grid
                  container
                  className={`${classes.containerGrid} ${classes.secondRow}`}
                >
                  <Grid>
                    <img className="logo_desktop" src={logo2} />
                  </Grid>
                  <Grid>
                    <img className="logo_desktop" src={logo1} />
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default INostriPartner;

import React, { useEffect } from "react";
import CoverImage from "../../components/ICorsi/CoverImage.jsx";
import EnrolledSection from "../../components/ICorsi/EnrolledSection.jsx";
import CoursePrice from "../../components/ICorsi/CoursePrice.jsx";
import DocentsSection from "../../components/ICorsi/DocentsSection.jsx";
import TemiSection from "../../components/ICorsi/TemiSection.jsx";
import Form from "../../components/ICorsi/Form.jsx";
import VideoBlock from "../../components/ICorsi/Video.jsx";
import Matrrie from "../../components/ICorsi/Matrrie.jsx";
import Ammissione from "../../components/ICorsi/Ammissione.jsx";
import IlCorsoAsincrono from "../../components/ICorsi/IlCorsoAsincrono.jsx";
import Club from "../../components/ICorsi/Club.jsx";
import ILCORSO from "../../components/ICorsi/ILCORSO2021.jsx";

const Icorsi = () => {
  
  return (
    <div>
      <CoverImage />
      <EnrolledSection />
      <VideoBlock />
      <CoursePrice />
      <Matrrie />
      <TemiSection />
      <Ammissione />
      {/* <Club /> */}
      <IlCorsoAsincrono />
      {/* <DocentsSection /> */}
      {/*  <ILCORSO /> */}
      <Form />
    </div>
  );
};

export default Icorsi;

import React, { useState } from "react";
import { Typography, TextField, Button } from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import itLocale from "date-fns/locale/it";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const useStyles = makeStyles((theme) => ({
  grow: {
    backgroundColor: "#ccd8ee",
    padding: "53px 112px",
    [theme.breakpoints.down("md")]: {
      padding: 40,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 40,
    },
  },
  input: {
    backgroundColor: "#fff",
    marginRight: 20,
    "& div": {
      borderRadius: 0,
    },
  },
  title: {
    marginBottom: 50,
    [theme.breakpoints.down("md")]: {
      marginBottom: 40,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 40,
    },
  },
  upload: {
    borderRadius: 0,
  },
  button: {
    marginTop: 16,
    backgroundColor: "#00416b",
    color: "#fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#00416b",
      color: "#fff",
    },
  },
  uploadfile: {
    display: "flex",
  },
  validation: {
    marginBottom: 16,
    marginTop: 10,
    display: "block",
    color: "red",
  },
  file_type: {
    marginBottom: 16,
    marginTop: 10,
    fontSize: "9pt",
    display: "block",
    color: "#929292",
  },
  labellink: {
    color: "#00416b",
  },
  label: {
    paddingBottom: 8,
  },
  terms: {
    paddingTop: 30,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
    },
  },
}));

const schema = yup.object().shape({
  CV: yup
    .mixed()
    .required("You need to provide a file")
    .test(
      "type",
      "Sono supportati solo i file PDF, DOC, DOCX, JPEG o PNG.",
      (value) => {
        return (
          //Only file typy PDF, DOC, DOCX, JPG or PNG
          (value && value[0].type === "application/pdf") ||
          value[0].type === "application/msword" ||
          value[0].type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          value[0].type === "image/jpeg" ||
          value[0].type === "image/png"
        );
      }
    )
    .test(
      "fileSize",
      "Dimensione massima del file: 10 Mb. Per favore, caricare un file più piccolo.",
      (value) => {
        console.log(value);
        // Only files <= 10Mb
        return value && value[0].size <= 10485760;
      }
    ),
  cover_letter: yup
    .mixed()
    .required("You need to provide a file")
    .test(
      "type",
      "Sono supportati solo i file PDF, DOC, DOCX. ,JPEG o PNG.",
      (value) => {
        return (
          //Only file typy PDF, DOC, DOCX, JPG or PNG
          (value && value[0].type === "application/pdf") ||
          value[0].type === "application/msword" ||
          value[0].type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          value[0].type === "image/jpeg" ||
          value[0].type === "image/png"
        );
      }
    )
    .test(
      "fileSize",
      "Dimensione massima del file: 10 Mb. Per favore, caricare un file più piccolo.",
      (value) => {
        console.log(value);
        // Only files <= 10Mb
        return value && value[0].size <= 10485760;
      }
    ),
});

const onSubmit = () => {
  const form = document.getElementById("enroll_request_form");
  const formData = new FormData(form);
  fetch("/submit_registration_request", {
    method: "POST",
    body: formData,
  })
    .then((res) => {
      if (res.status < 400) {
        window.location.href = "/grazie-registrazione";
        return;
      }
      console.error("Form submission was unsuccessful");
      // TODO: Display an error message
    })
    .catch((err) => {
      console.error(err);
      // TODO: Display an error message
    });
};

const Form = () => {
  const classes = useStyles();
  const { register, errors, handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
  });

  const [option, setOption] = React.useState();

  const handleChangeSelect = (event) => {
    setOption(event.target.value);
  };

  const [selectedDate, handleDateChange] = useState("1990-10-01");
  const [selectedYear, handleYearChange] = useState("2025");

  return (
    <div id="registration-request" className="icorsi-form">
      <div className={classes.grow}>
        <div className={classes.title}>
          <Typography variant="h1" id="form-title">
            ISCRIVITI AL CORSO “LE CHIAVI DEL MONDO”
          </Typography>
          <Typography>
            Sono aperte le iscrizioni al corso che inizierà ad Aprile 2025. I
            partecipanti saranno valutati in base al curriculum e alla lettera
            di presentazione. Se la Direzione didattica lo riterrà necessario,
            potranno essere contattati per un colloquio finale.
          </Typography>
        </div>
        <div className="form_warpper">
          <form
            action="/submit_registration_request"
            method="POST"
            encType="multipart/form-data"
            id="enroll_request_form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="form_container">
              <div>
                <label>Nome *</label>
                <TextField
                  name="nome"
                  required={true}
                  className={classes.input}
                  placeholder="Inserisci"
                  variant="outlined"
                  inputRef={register({
                    pattern: /^[^\d"@!?/|]+$/i,
                  })}
                />
                <span className={classes.validation}>
                  {errors.nome &&
                    'Il nome non può contenere nessuno di questi caratteri: " @  ! ? / | 1 3 5 '}
                </span>
              </div>
              <div>
                <label>Cognome *</label>
                <TextField
                  name="cognome"
                  required={true}
                  className={classes.input}
                  placeholder="Inserisci"
                  variant="outlined"
                  inputRef={register({
                    pattern: /^[^\d"@!?/|]+$/i,
                  })}
                />
                <span className={classes.validation}>
                  {errors.cognome &&
                    'Il cognome non può contenere nessuno di questi caratteri: " @  ! ? / | 1 3 5 '}
                </span>
              </div>
            </div>

            <div className="form_container">
              <div className="dropdown">
                <label className={classes.label}>
                  Come sei venuto a conoscenza della Scuola? *
                </label>
                <select
                  required
                  name="fonte"
                  value={option}
                  defaultValue="Scegli la fonte"
                  onChange={handleChangeSelect}
                  ref={register({
                    required: true,
                  })}
                >
                  <option value="Scegli la fonte" disabled="disabled">
                    Scegli la fonte
                  </option>
                  <option value="Pubblicità stampa">Pubblicità stampa</option>
                  <option value="Radio">Radio</option>
                  <option value="Internet">Internet</option>
                  <option value="Amici o familiari">Amici o familiari</option>
                  <option value="Festival di Limes">Festival di Limes</option>
                  <option value="Limes Club">Limes Club</option>
                  <option value="Altro">Altro</option>
                </select>
              </div>
            </div>

            <div className="form_container_end">
              <div>
                <label>Città di residenza *</label>
                <TextField
                  name="paese"
                  required={true}
                  className={classes.input}
                  placeholder="Inserisci"
                  variant="outlined"
                  inputRef={register()}
                />
              </div>
              <div>
                <label>Nazionalità *</label>
                <TextField
                  name="provincia"
                  required={true}
                  className={classes.input}
                  placeholder="Inserisci"
                  variant="outlined"
                  inputRef={register()}
                />
              </div>
              <div>
                <label>Data di nascita *</label>
                <div className="birthday">
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={itLocale}
                  >
                    <KeyboardDatePicker
                      disableFuture
                      required={true}
                      name="birthday"
                      variant="dialog"
                      cancelLabel="annullare"
                      inputVariant="outlined"
                      openTo="year"
                      invalidDateMessage="Formato della data non valido"
                      minDateMessage="La data non deve essere antecedente alla data minima"
                      className={classes.input}
                      format="dd/MM/yyyy"
                      views={["year", "month", "date"]}
                      value={selectedDate}
                      onChange={(date) => handleDateChange(date)}
                      inputRef={register()}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>

            <div className="form_container_third">
              <div>
                <label>Email *</label>
                <TextField
                  name="email"
                  type="email"
                  required={true}
                  className={classes.input}
                  placeholder="Inserisci"
                  variant="outlined"
                  inputRef={register()}
                />
                <span className={classes.validation}>
                  {errors.email &&
                    "Il email non può contenere nessuno di questi caratteri: ! # $ % ^ "}
                </span>
              </div>
              <div>
                <label>Numero telefono </label>
                <TextField
                  name="telefono"
                  type="tel"
                  className={classes.input}
                  placeholder="Inserisci"
                  variant="outlined"
                  inputRef={register()}
                />
              </div>
            </div>
            <div className="form_container_forth">
              <div>
                <label>Anno Accademico *</label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    required={true}
                    name="anno_accademico"
                    views={["year"]}
                    value={selectedYear}
                    cancelLabel="annullare"
                    inputVariant="outlined"
                    className={classes.input}
                    onChange={handleYearChange}
                  />
                </MuiPickersUtilsProvider>
              </div>

              <div>
                <label>Allega il tuo CV *</label>
                <TextField
                  name="CV"
                  type="file"
                  required={true}
                  variant="outlined"
                  className={classes.input}
                  id="uploadfile"
                  rowsMax={16}
                  inputRef={register()}
                />
                <span className={classes.file_type}>
                  PDF, DOC, DOCX, PNG o JPG (max. 10Mb)
                </span>
                <span className={classes.validation}>
                  {errors.CV && errors.CV.message}
                </span>
              </div>
              <div>
                <label>Lettera di presentazione *</label>
                <TextField
                  name="cover_letter"
                  type="file"
                  required={true}
                  variant="outlined"
                  className={classes.input}
                  id="uploadfile"
                  rowsMax={16}
                  inputRef={register()}
                />
                <span className={classes.file_type}>
                  PDF, DOC, DOCX, PNG o JPG (max. 10Mb)
                </span>
                <span className={classes.validation}>
                  {errors.cover_letter && errors.cover_letter.message}
                </span>
              </div>
            </div>
            <div className="form_container_end_textarea">
              <label>Messaggio</label>
              <TextareaAutosize
                name="messaggio"
                className={classes.input}
                rowsMax={16}
                aria-label="maximum height"
                placeholder="Compila questo campo se hai delle richieste specifiche"
                ref={register()}
              />
            </div>
            <Typography className={classes.label}>
              * Campi obbligatori
            </Typography>
            <div className={`terms-AL-CORSO`}>
              <FormControlLabel
                control={
                  <Checkbox
                    required={true}
                    name="terms_and_conditions_accepted"
                    color="primary"
                    inputRef={register()}
                  />
                }
                label={
                  <span>
                    Dichiaro di aver letto e compreso{" "}
                    <a className={classes.labellink} href="./candidature">
                      l'informativa privacy
                    </a>{" "}
                    e accetto le{" "}
                    <a
                      className={classes.labellink}
                      href="./condizionidelservizio"
                    >
                      Condizioni di servizio
                    </a>
                  </span>
                }
              />

              <FormControlLabel
                control={
                  <Checkbox
                    required={false}
                    name="GEDI_accepted"
                    value="GEDI_accepted"
                    color="primary"
                    inputRef={register()}
                  />
                }
                label={
                  <span>
                    Cliccando qui presti il consenso a che GEDI Periodici e
                    Servizi S.p.A. e le società appartenenti al Gruppo GEDI
                    trattino i tuoi dati personali per l'invio di comunicazioni
                    commerciali inerenti alle attività della Scuola di Limes e
                    ai propri prodotti e servizi.
                  </span>
                }
              />
              <br />
              <Typography className="important-note">
                IMPORTANTE: al corretto ricevimento della sua candidatura le
                verrà inviata una mail di conferma all’indirizzo mail indicato
                nel form entro le prossime 24 ore. Qualora, scaduto tale
                termine, non la avesse ricevuta, la preghiamo di segnalarcelo
                all’indirizzo mail{" "}
                <a target="_blank" href="mailto:info@scuoladilimes.it">
                  info@scuoladilimes.it
                </a>
                .
              </Typography>

              <Button className={classes.button} type="submit">
                INVIA
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Form;
